import { request, noTimeOutReq } from '@/utils/request.js'

//分页展示AI阅卷记录
// export function getAiMarkRecordApi (pageIndex, pageSize, formData) {
//   return request({
//     url: `/ai/result/class/operation/question/page/${pageIndex}/${pageSize}/list`,
//     method: 'post',
//     data: formData
//   })
// }
export function getAiMarkRecordApi (pageIndex, pageSize, formData) {
  return request({
    url: `/ai/result/operation/question/page/${pageIndex}/${pageSize}/list`,
    method: 'post',
    data: formData
  })
}


//删除AI阅卷记录详情
// export function deleteAiMarkRecordApi (data) {
//   return request({
//     url: `/ai/result/class/operation/question/delete`,
//     method: 'post',
//     data
//   })
// }
// 删除AI阅卷记录详情
export function deleteAiMarkRecordApi (data) {
  return request({
    url: `/ai/result/operation/question/delete`,
    method: 'post',
    data
  })
}


//全部展示AI阅卷记录-根据批次
export function getQuestionResultApi (data) {
  return request({
    url: `/ai/result/class/operation/question/list/batch`,
    method: 'post',
    data
  })
}

//导出AI阅卷记录详情
export function exportAIResultApi (data) {
  return request({
    url: `/ai/result/class/operation/question/export`,
    method: 'post',
    data
  })
}

//导出AI阅卷记录详情-评多次
export function exportAIResultTimeNumApi (data) {
  return request({
    url: `/ai/result/class/operation/question/export/timeNum`,
    method: 'post',
    data
  })
}
//导出AI阅卷记录视频
export function exportVideoApi (data) {
  return request({
    url: `/ai/result/class/operation/question/export/video`,
    method: 'post',
    data
  })
}

//获取AI阅卷记录,根据experimentResultCode
export function getAIResultOperation (data) {
  return request({
    url: `/ai/result/operation/question/get/experiment/result/code`,
    method: 'post',
    data
  })
}